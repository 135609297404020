.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.active {
  text-decoration: underline;
}
