@import "~bootstrap/scss/bootstrap";

body {
  background-color: rgb(32, 32, 32);
  color: #f9f9f9;
}

main {
  margin: 0 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10%;
}

.logo {
  width: 100px;
}

#dropdown-basic-button {
  background-color: #f9f9f9;
  color: #201f1f;
  border: none;
}

.msgcontainer {
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
}

/* News */

.newsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  .newsarticle {
    background-color: #626060;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid black;
    min-height: 500px;
    width: 300px;
    img {
      object-fit: cover;
      width: 100%;
      height: 200px;
      border-radius: 5px 5px 0 0;
    }
    a {
      color: #201f1f;
      align-items: baseline;
    }
    :hover {
      color: #f9f9f9;
    }
  }
}
h1 {
  text-align: center;
  margin-top: 50px;
}

form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  height: 100%;
  margin: 20px 0 60px;
  div {
    display: flex;
    flex-direction: column;
  }
  input,
  button,
  select {
    background-color: #a8a8a8;
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    font-weight: bold;
  }
  button:hover {
    background-color: #f9f9f9;
    color: #201f1f;
  }

  #labelCountry {
    grid-column: 3/5;
  }
}

.word {
  p {
    text-align: center;
  }
  form {
    grid-template-columns: 2fr 1fr;
  }
  p {
    text-transform: capitalize;
  }
}

.facts {
  margin: 0 15%;
  .fact {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.weather2Input,
.weather1input {
  background-color: #a8a8a8;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  font-weight: bold;
  width: 80%;
}

.weather2,
.weather1 {
  display: flex;
  h2 {
    font-size: 2rem;
  }

  .img-container {
    width: 200px;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
}

.weather3 {
  margin-top: 10%;
  display: flex;
  align-items: center;
  gap: 20px;
  .weather2 {
    display: block;
  }
  .img-container {
    width: 100px;
  }
}

//To do

.toDo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  .createTodo {
    grid-row: 1/3;
    background-color: rgb(54, 73, 90);
  }
  .toDo-card {
    background-color: #626060;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px black;
    h4 {
      font-size: 1.5rem;
      font-weight: 200;
    }

    .task {
      font-weight: bold;
      text-transform: capitalize;
      font-size: 1.2rem;
    }
    .date {
      font-size: 0.8rem;
    }

    form {
      display: block;
    }

    input,
    select {
      font-size: 1rem;
      width: -webkit-fill-available;
      margin-bottom: 20px;
      margin-top: 5px;
    }

    button {
      width: 100%;
    }
  }
}

.todo .createTodo .wishcard {
  grid-row: 1/2;
}

.wishcard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.imgContainer {
  max-width: 200px;
  img {
    object-fit: contain;
    width: 100%;
  }
}

.edit-head {
  h1 {
    margin-top: 0;
  }
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
}
